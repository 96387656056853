
import { defineComponent, ref, computed } from 'vue'
import { directive } from 'vue3-click-away'
export default defineComponent({
  directives: {
    ClickAway: directive
  },
  setup(props, context) {
    const menuVisible = ref(false)
    function onClickAway() {
      menuVisible.value = false
    }
    const hasMenu = computed(() => {
      return context.slots.menu
    })
    return { onClickAway, menuVisible, hasMenu }
  }
})
