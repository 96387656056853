<template>
  <div class="block">
    <div class="block__row">
      <h3 class="block__heading">
        <slot name="heading" />
      </h3>
      <slot name="questionIcon" />
      <slot name="status" />
    </div>
    <div class="block__row">
      <h2 class="block__result"><slot name="score" /></h2>
      <div class="block__performance">
        <slot name="performance" />
      </div>
    </div>
    <slot name="blockFields" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({})
</script>

<style lang="sass">
.block
  width: 100%
  grid-column: span 2 / auto
  background: $white
  padding-top: 1rem
  &__row
    display: flex
    padding: 1rem 2rem
    justify-content: space-between
    align-items: center
    border-bottom: solid 1px map-get($greyShades, 'greyShade10')
    a
      text-decoration: none
    @media screen and (max-width: $lg-breakpoint)
      padding: 0.8rem
  &__heading
    font-weight: normal
    margin: 0
  &__status
    width: 16px
    height: 16px
    border-radius: 50%
    margin-left: 0.7rem
    display: inline-block
  &__result
    font-size: 2.5rem
    margin: 0
    padding: 1rem
    @media screen and (max-width: $lg-breakpoint)
      font-size: 1.8rem
  &__performance
    .avg
      background: $primary
      color: $white
      font-size: 1.2rem
      border-radius: 4px
      width: 70px
      padding: 0.8rem
      text-align: center
      margin: 10px 0 10px auto
      display: flex
      align-items: center
      span
        margin-right: 0.4rem
    span
      color: map-get($greyShades, 'greyShade60')
      font-size: 0.75rem
  .dot
    width: 10px
    height: 10px
    border-radius: 50%
    margin-left: 0.7rem
    display: inline-block
  .green-dot
    background: $green
  .mustard-dot
    background: $mustard
  .red-dot
    background: red
  .arrow-up
    width: 0
    height: 0
    border-left: 0.5rem solid transparent
    border-right: 0.5rem solid transparent
    border-bottom: 0.5rem solid $green
  .arrow-down
    width: 0
    height: 0
    border-left: 0.5rem solid transparent
    border-right: 0.5rem solid transparent
    border-top: 0.5rem solid $red
  img
    max-width: 15px
    max-height: 15px
  .inline-flex
    display: -webkit-inline-box
    display: -ms-inline-flexbox
    display: inline-flex
  .ml-auto
    margin-left: auto
</style>
