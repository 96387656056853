<template>
  <DashboardSection>
    <template #content>
      <div class="block-wrapper">
        <OverviewBlock>
          <template #heading> Total users </template>
          <template #score> 1254 </template>
          <template #performance>
            <p class="avg"><span class="arrow-up"></span>10%</p>
            <span>30 day rolling avg.</span>
          </template>
        </OverviewBlock>
        <OverviewBlock>
          <template #heading> Lead users </template>
          <template #score> 54 </template>
          <template #performance>
            <p class="avg"><span class="arrow-up"></span>33%</p>
            <span>30 day rolling avg.</span>
          </template>
        </OverviewBlock>
        <OverviewBlock>
          <template #heading> Active users </template>
          <template #score> 1132 </template>
          <template #performance>
            <p class="avg"><span class="arrow-up"></span>1%</p>
            <span>30 day rolling avg.</span>
          </template>
        </OverviewBlock>
        <h3>Users</h3>
      </div>
      <TableTile>
        <template #tileContent>
          <div class="timesheet-member heading">
            <div class="tile-content">
              <p class="tile-content__title">Name</p>
            </div>
            <div class="tile-content">
              <p class="tile-content__title">Studio</p>
            </div>
            <div class="tile-content">
              <div class="tile-content__title">Role</div>
            </div>
            <div class="tile-content">
              <div class="tile-content__title">Since</div>
            </div>
            <div class="tile-content">
              <div class="tile-content__title">Last login</div>
            </div>
            <div class="tile-content">
              <div class="tile-content__title">Status</div>
            </div>
          </div>
        </template>
      </TableTile>
      <TableTile>
        <template #tileContent>
          <div class="timesheet-member invoice-main status-green">
            <div class="tile-content">
              <p class="tile-content__title">Team member</p>
            </div>
            <!-- <div class="left-content"> -->
            <div class="tile-content">
              <p class="tile-content__title">Hamburg Co.</p>
            </div>
            <div class="tile-content">
              <div class="tile-content__title">Owner</div>
            </div>
            <div class="tile-content">
              <div class="tile-content__title">23/08/21</div>
            </div>
            <div class="tile-content">
              <div class="tile-content__title">3 days ago</div>
            </div>
            <div class="tile-content">
              <div class="status-main">Active</div>
            </div>
            <!-- </div> -->
          </div>
        </template>
        <template #menu>
          <ul>
            <li>Delete</li>
          </ul>
        </template>
      </TableTile>
      <TableTile>
        <template #tileContent>
          <div class="timesheet-member invoice-main status-green">
            <div class="tile-content">
              <p class="tile-content__title">Team member</p>
            </div>
            <!-- <div class="left-content"> -->
            <div class="tile-content">
              <p class="tile-content__title">Hamburg Co.</p>
            </div>
            <div class="tile-content">
              <div class="tile-content__title">Owner</div>
            </div>
            <div class="tile-content">
              <div class="tile-content__title">23/08/21</div>
            </div>
            <div class="tile-content">
              <div class="tile-content__title">3 days ago</div>
            </div>
            <div class="tile-content">
              <div class="status-main">Active</div>
            </div>
            <!-- </div> -->
          </div>
        </template>
        <template #menu>
          <ul>
            <li>Delete</li>
          </ul>
        </template>
      </TableTile>
      <TableTile>
        <template #tileContent>
          <div class="timesheet-member invoice-main status-green">
            <div class="tile-content">
              <p class="tile-content__title">Team member</p>
            </div>
            <!-- <div class="left-content"> -->
            <div class="tile-content">
              <p class="tile-content__title">Hamburg Co.</p>
            </div>
            <div class="tile-content">
              <div class="tile-content__title">Owner</div>
            </div>
            <div class="tile-content">
              <div class="tile-content__title">23/08/21</div>
            </div>
            <div class="tile-content">
              <div class="tile-content__title">3 days ago</div>
            </div>
            <div class="tile-content">
              <div class="status-main">Active</div>
            </div>
            <!-- </div> -->
          </div>
        </template>
        <template #menu>
          <ul>
            <li>Delete</li>
          </ul>
        </template>
      </TableTile>
      <TableTile>
        <template #tileContent>
          <div class="timesheet-member invoice-main status-green">
            <div class="tile-content">
              <p class="tile-content__title">Team member</p>
            </div>
            <!-- <div class="left-content"> -->
            <div class="tile-content">
              <p class="tile-content__title">Hamburg Co.</p>
            </div>
            <div class="tile-content">
              <div class="tile-content__title">Owner</div>
            </div>
            <div class="tile-content">
              <div class="tile-content__title">23/08/21</div>
            </div>
            <div class="tile-content">
              <div class="tile-content__title">3 days ago</div>
            </div>
            <div class="tile-content">
              <div class="status-main">Active</div>
            </div>
            <!-- </div> -->
          </div>
        </template>
        <template #menu>
          <ul>
            <li>Delete</li>
          </ul>
        </template>
      </TableTile>
      <TableTile>
        <template #tileContent>
          <div class="timesheet-member invoice-main status-green">
            <div class="tile-content">
              <p class="tile-content__title">Team member</p>
            </div>
            <!-- <div class="left-content"> -->
            <div class="tile-content">
              <p class="tile-content__title">Hamburg Co.</p>
            </div>
            <div class="tile-content">
              <div class="tile-content__title">Owner</div>
            </div>
            <div class="tile-content">
              <div class="tile-content__title">23/08/21</div>
            </div>
            <div class="tile-content">
              <div class="tile-content__title">3 days ago</div>
            </div>
            <div class="tile-content">
              <div class="status-main">Active</div>
            </div>
            <!-- </div> -->
          </div>
        </template>
        <template #menu>
          <ul>
            <li>Delete</li>
          </ul>
        </template>
      </TableTile>
    </template>
  </DashboardSection>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import DashboardSection from '@/components/atom/DashboardSection.vue'
import OverviewBlock from '@/components/atom/OverviewBlock.vue'
import TableTile from '@/components/templates/TableTile.vue'

export default defineComponent({
  name: 'ProjectDashboard',
  components: {
    OverviewBlock,
    DashboardSection,
    TableTile
  }
})
</script>

<style lang="sass" scoped>
.block-wrapper
  width: 100%
  display: grid
  grid-template-columns: repeat(6, 1fr)
  justify-content: space-between
  grid-gap: 1rem
  @media screen and (max-width: $ms-breakpoint)
    grid-template-columns: 1fr
  .subtext
    color: $text-grey
    margin: 0.5rem
  h3
    margin-left: 30px
</style>

<style lang="sass" scoped>
$leftWidth: 10rem
$rightWidth: 5rem
.timesheet-table
  display: flex
  padding: 2rem
.timesheet-table-item
  &:first-child
    flex: 2
    flex: 0 65em
    margin-right: 1rem
    @media(max-width: $lg-breakpoint)
      flex: 2
    @media(max-width: $ms-breakpoint)
      margin: unset
  &:last-child
    flex: 1
    flex: 0 35em
    @media(max-width: $lg-breakpoint)
      flex: 1
    @media(max-width: $ms-breakpoint)
      margin-bottom: 1rem
      width: 100%
      border: 1px solid #CFD2D7
      border-radius: 2px
      background-color: $white
      width: 100%
.tile-content
  cursor: pointer
  display: flex
  align-items: center
  justify-content: flex-start
  flex: 1
  width: 100%
  position: relative
  @media(max-width: $ms-breakpoint)
    padding: 1rem 0
    padding-left: 1rem
.tile-icon
  margin-left: 1.5rem
  margin-right: 0.5rem
  display: flex
  align-items: center
  justify-content: center
  img
    width: 1.5rem
    height: 1.5rem
  @media(max-width: $ms-breakpoint)
    // padding: 1rem 0
    padding: 0
    margin: 0
.tile-row
  display: flex
  width: 100%
  flex: 2
  & > *
    flex: 1
    width: 100%
.tableTile-wrapper
  &:not(&:last-child)
    border-bottom: 1px solid #CFD2D7
.timesheet-member
  padding-left: 1rem
  padding-right: 3rem
  margin: 0
  // border: 1px solid
  display: flex
  @media(max-width: $ms-breakpoint)
    flex-direction: column
.dropdown-wrapper
  position: absolute
  z-index: 250
  left: 0
  top: 100%
  width: 100%
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.05)
  max-height: 10rem
  overflow-y: scroll
  box-shadow: 0px 4px 13px rgba(14, 30, 54, 0.1)
  border: 0.5px solid map-get($greyShades, 'greyShade10')
  &::-webkit-scrollbar
    width: 0
.status-green
  border-color: $green
  .status-main
    background-color: $green
.left-content, .right-content
  display: flex
  align-items: center
  & > *
    text-align: center
    color: $primary
    font-size: 14px
.left-content > *
  min-width: $leftWidth
  & > *
    padding-left: 2rem
.heading
  height: 50px
  .tile-content__title
    color: $text-grey
.status-red
  border-color: $red
  .status-main
    background-color: $red
.status-yellow
  border-color: $mustard
  .status-main
    background-color: $mustard
.status-grey
  border-color: map-get($greyShades, 'greyShade30')
  .status-main
    background-color: map-get($greyShades, 'greyShade30')
.status-green
  border-color: $green
  .status-main
    background-color: $green
.status-main
  display: flex
  padding: 0.5rem
  border-radius: 2px
  color: $white
.invoice-main
  border-left: 4px solid $green
  border-radius: 4px
</style>
