<template>
  <div class="tableTile-wrapper">
    <div class="table-tile">
      <slot name="tileContent"> </slot>
    </div>
    <div class="dots-wrapper" @click="menuVisible = true" v-if="hasMenu">
      <img class="options" src="@/assets/icons/dots.svg" />
    </div>
    <div class="menu-wrapper" v-if="menuVisible" v-click-away="onClickAway">
      <div class="tile-menu">
        <slot name="menu" :clicked="onClickAway" />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, computed } from 'vue'
import { directive } from 'vue3-click-away'
export default defineComponent({
  directives: {
    ClickAway: directive
  },
  setup(props, context) {
    const menuVisible = ref(false)
    function onClickAway() {
      menuVisible.value = false
    }
    const hasMenu = computed(() => {
      return context.slots.menu
    })
    return { onClickAway, menuVisible, hasMenu }
  }
})
</script>

<style lang="sass">
.tableTile-wrapper
  position: relative
  .dots-wrapper, .menu-wrapper
    position: absolute
    right: 0rem
    cursor: pointer
    background-color: transparent
    padding: 0 1rem
    top: 50%
    transform: translateY(-50%)
    display: flex
  .menu-wrapper
    z-index: 2
.table-tile
  position: relative
  width: 100%
.table-tile > *
  display: flex
  align-items: center
  justify-content: space-between
  background-color: $white
  margin-bottom: 1rem
  padding: 1rem 2rem 1rem 0rem
  position: relative
  text-decoration: none
  & > *
    & > *
      word-break: break-all
      text-align: center
      color: $primary
      padding-left: 1rem
.options
  position: relative
  height: 15px
.tile-menu
  position: absolute
  background-color: $white
  box-shadow: 0px 4px 13px rgba(14, 30, 54, 0.1)
  border-radius: 3px
  right: 0
  z-index: 200
  @media( max-width: $md-breakpoint)
    left: -2rem
  ul
    list-style: none
    margin: 0
    padding: 0
    li
      padding: 0.5rem 1rem 0.5rem 1rem
      cursor: pointer
      transition: all .2s
      &:hover
        background-color: map-get($greyShades, 'greyShade10')
.left-content > *
  &:first-child
    margin-left: 0rem
</style>
